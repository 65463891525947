import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/bulma-styles.scss';

import Header from './Header';
import Footer from './Footer';

import * as styles from './Layout.style';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Layout = ({ children }) => (
  <div css={styles.layoutWrapper}>
    <Header />
    <main css={styles.main}>{children}</main>
    <Footer />
  </div>
);

Layout.propTypes = propTypes;

export default Layout;
