import { css } from '@emotion/core';

export const layoutWrapper = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 3.25rem;
`;

export const main = css`
  flex: 1 0 auto;
`;
