import React from 'react';

const BottleIcon = () => (
  <svg
    enableBackground="new 0 0 64 64"
    height="32"
    viewBox="0 0 64 64"
    width="32"
  >
    <path
      d="m36 10 1.291 9.686c.448 3.358 2.401 6.329 5.305 8.072 3.353 2.011 5.404 5.634 5.404 9.543v1.699l-4 23h-24l-4-23v-1.699c0-3.909 2.051-7.532 5.403-9.543 2.905-1.743 4.858-4.714 5.305-8.072l1.292-9.686c-1.105 0-2-.895-2-2v-2h12v2c0 1.105-.895 2-2 2z"
      fill="#590004"
    />
    <path
      d="m41.907 27.289c-2.517-1.78-4.205-4.523-4.616-7.603l-1.291-9.686c1.105 0 2-.895 2-2v-1.422c-.096-.194-.194-.387-.294-.578h-11.706v2c0 1.105.895 2 2 2l-1.292 9.686c-.448 3.358-2.401 6.329-5.305 8.072-3.352 2.011-5.403 5.634-5.403 9.543v1.699l2.953 16.981c.182.006.364.019.547.019 11.754 0 21.398-12.621 22.407-28.711z"
      fill="#801419"
    />
    <path d="m28 6v-4h8v4" fill="#360003" />
    <path d="m37.067 18-.534-4h-9.066l-.534 4z" fill="#aa373d" />
    <path
      d="m26 45c0 3.314 2.686 6 6 6s6-2.686 6-6h8.957l1.043-6h-10c0-3.314-2.686-6-6-6s-6 2.686-6 6h-10l1.043 6z"
      fill="#aa373d"
    />
    <path d="m23 55h18v2h-18z" fill="#fff" />
  </svg>
);

export default BottleIcon;
