import { css } from '@emotion/core';

export const footer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const dateBanner = css`
  padding: 1rem 1.5rem;
`;

export const facebook = css`
  padding: 1rem 1.5rem;
`;
