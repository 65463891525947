import { css } from '@emotion/core';

export const mobileOnly = mobileOnlyStyles => {
  return css`
    @media (max-width: 767px) {
      ${mobileOnlyStyles};
    }
  `;
};

export const tabletAndUp = tabletAndUpStyles => {
  return css`
    @media (min-width: 768px) {
      ${tabletAndUpStyles};
    }
  `;
};
