import React from 'react';

import BottleIcon from '../../icons/BottleIcon';

const Logo = () => (
  <>
    <BottleIcon />
    <div className="subtitle is-5">
      Mega Liquor <span className="has-text-primary">Warehouse</span>
    </div>
  </>
);

export default Logo;
