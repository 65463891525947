import React from 'react';
import { FaFacebookF } from 'react-icons/fa';

import * as styles from './Footer.style';

const Footer = () => (
  <footer className="has-background-grey-light" css={styles.footer}>
    <div css={styles.dateBanner}>
      <p>© {new Date().getFullYear()}, Mega Liquor Warehouse, Inc.</p>
    </div>
    <a css={styles.facebook} href="http://facebook.com/megaliquorwarehouse">
      <FaFacebookF />
    </a>
  </footer>
);

export default Footer;
