import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './SectionContainer.style';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const SectionContainer = ({ children }) => {
  return (
    <div css={styles.section}>
      <div className="container">{children}</div>
    </div>
  );
};

SectionContainer.propTypes = propTypes;

export default SectionContainer;
