import React, { useState } from 'react';
import { Link } from 'gatsby';
import { ClassNames } from '@emotion/core';

import Logo from './Logo';

import { getRouteDefinitions } from '../../routes';

const routes = getRouteDefinitions();

const Header = () => {
  const [isActive, setToggle] = useState(false);

  const conditionalMenuClasses = { 'is-active': isActive };

  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to={routes.index}>
          <Logo />
        </Link>
        <ClassNames>
          {({ cx }) => (
            <div
              className={cx('navbar-burger burger', conditionalMenuClasses)}
              onClick={() => setToggle(!isActive)}
              role="button"
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          )}
        </ClassNames>
      </div>
      <ClassNames>
        {({ cx }) => (
          <div className={cx('navbar-menu', conditionalMenuClasses)}>
            <div className="navbar-end">
              <a className="navbar-item" href="tel:951-652-6575">
                (951) 652 - 6575
              </a>
              <Link
                activeClassName="is-active"
                className="navbar-item"
                to={routes.tastings}
              >
                Free Tastings
              </Link>
              <Link
                activeClassName="is-active"
                className="navbar-item"
                to={routes.kegInfo}
              >
                Keg Info
              </Link>
              <Link
                activeClassName="is-active"
                className="navbar-item"
                to={routes.ad}
              >
                Latest Ad
              </Link>
              <Link
                activeClassName="is-active"
                className="navbar-item"
                to={routes.contact}
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </ClassNames>
    </nav>
  );
};

export default Header;
