import { css } from '@emotion/core';

import { tabletAndUp } from '../../styles/mediaQueries';

export const section = css`
  padding: 1rem 1.5rem;

  ${tabletAndUp(css`
    padding: 2rem 1.5rem;
  `)};
`;
